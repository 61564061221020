<template>
<form v-on:submit="addIncome">
  <div class="flex mt-5">
    <div class="flex-1 mr-2">
      <label for="incomeType" class="form-label font-bold"
        >Tipo de Ingreso</label
      >
      <select
        class="form-control"
        id="incomeType"
        v-model="formInputs.incomeType"
        required
      >
        <option value="">--Seleccionar--</option>
        <option v-for="option in typesIncomes" :value="option" :key="option">
          {{ option }}
        </option>
      </select>
      <MessageError text="" />
    </div>

    <div class="flex-1" v-if="formInputs.incomeType == 'Cuota'">
      <label for="password" class="form-label font-bold">Concepto</label>
      <input
        type="text"
        class="form-control"
        id="password"
        v-model="formInputs.concept"
        required
      />
    </div>

    <div class="flex-1" v-else>
      <template v-if="formInputs.incomeType == 'Reembolso'">
        <label for="amount" class="form-label font-bold">Valor</label>
        <input
          type="text"
          class="form-control"
          id="amount"
          v-model="formInputs.amount"
          required
        />
      </template>
      <template v-else>
        <label for="numdoc" class="form-label font-bold">N° de Documento</label>
        <input
          type="text"
          class="form-control"
          id="numdoc"
          v-model="formInputs.numberDoc"
          required
        />
      </template>
    </div>
  </div>

  <div class="w-full">
    <div class="mt-5" v-if="formInputs.incomeType == 'Cuota'">
      <label for="numdoc" class="form-label font-bold">N° de Pagos </label>
      <input
        type="text"
        class="form-control"
        id="numdoc"
        v-model="formInputs.numberDoc"
        required
      />
    </div>
    <div class="mt-5" v-else>
      <label for="password" class="form-label font-bold">Concepto</label>
      <input type="text" class="form-control" v-model="formInputs.concept" required />
    </div>
  </div>

  <div class="flex justify-end mt-5">
    <div v-if="$route.name === 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos.editar'">
      <Button
        label="Guardar"
        icon="pi pi-save"
        class="p-button-sm p-4 mr-2"
        @click="editIncome"
      />
    </div>
    <div v-else>
      <Button
        label="Agregar Ingreso"
        icon="pi pi-plus"
        class="p-button-sm p-4 mr-2"
        type="submit"
      />
    </div>
    <Button
      label="Cancelar"
      class="p-button-sm p-4 bg-gray-700 border-b border-gray-200"
      @click="this.$router.back()"
    />
  </div>
  </form>
</template>
<script>
import { computed, onMounted, reactive } from 'vue'
import useIncome from '../hooks/useIncome'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { messageSuccess } from '@/libs/mensajes'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { typesIncomes, createIncome, updateIncome, getIncomes } = useIncome()
    let findIncome = {}

    const getStoreDate = computed(() => store.getters['storePettyCash/getStoreDate'])

    if (route.name === 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos.editar') {
      const storeIncomes = computed(
        () => store.getters['storePettyCash/getIncomes']
      )
      findIncome = storeIncomes.value.rows.find(
        (item) => item.id === parseInt(route.params.id)
      )
    }

    const formInputs = reactive({
      incomeType: findIncome ? findIncome.incomeType : null,
      numberDoc: findIncome ? findIncome.numberDoc : null,
      concept: findIncome ? findIncome.concept : null,
      amount: findIncome ? findIncome.amount : 0
    })

    const addIncome = async (e) => {
      e.preventDefault()
      try {
        await createIncome(formInputs)
        // GET DATA INCOME
        await getIncomes({ date: getStoreDate.value })
        messageSuccess('Operación exitosa!')
        router.back()
      } catch (err) {
        // errorHtml(err)
        console.error('Error al crear ingreso', err)
      }
    }

    const editIncome = async (e) => {
      e.preventDefault()
      try {
        await updateIncome(formInputs, route.params.id)
        messageSuccess('Operación exitosa!')
        // GET DATA INCOME
        router.back()
        await getIncomes({ date: getStoreDate.value })
      } catch (err) {
        // errorHtml(err)
        console.error('Error al editar ingreso', err)
      }
    }

    onMounted(() => {
      // store.dispatch('storePettyCash/storeIncome', route.params.id)
    })

    return {
      typesIncomes,
      formInputs,
      addIncome,
      editIncome
    }
  }
}
</script>
